<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="请输入名称"
            class="form-input-300"
            v-model="classifyName"
            clearable
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="getCate"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button class="ml-15" type="primary" @click="showDialog('add')"
                >新增分类</el-button
              >
              <el-button class="ml-15" type="danger" @click="deleteAll"
                >批量删除</el-button
              >
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            row-key="id"
            border
            lazy
            @selection-change="handleSelectionChange"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
              :selectable="selectable"
            ></el-table-column>
            <el-table-column prop="classifyName" label="名称"></el-table-column>
            <el-table-column
              label="状态"
              align="center"
              prop="isShow"
              width="100"
            >
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.isShow === 1" :type="''">显示</span>
                  <span v-else :type="'info'">隐藏</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="classifySort"
              label="排序"
              align="center"
              width="100"
              sortable
            ></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <span
                    class="tip-text-edit mr-20"
                    @click="showDialog('edit', scope.row)"
                    ><i class="el-icon-edit-outline">编辑</i></span
                  >
                  <el-popconfirm
                    title="确定删除此分类吗？"
                    @onConfirm="deleteDateOne(scope.row.id)"
                    v-if="!scope.row.children"
                  >
                    <i class="el-icon-delete tip-text-edit tip-text-delete" slot="reference"
                      >删除</i
                    >
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="getCate"
            @syncPageData="syncPageData"
          ></Pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="isShowDia"
      :title="opeType == 'add' ? '添加百货商品分类' : '编辑百货商品分类'"
      @close="clearDialog"
    >
      <div class="pad-container">
        <el-form
          class="mt-24"
          label-width="100px"
          :model="cate_form"
          :rules="rules"
          ref="cate_form"
        >
          <el-form-item label="分类名称" prop="classifyName">
            <el-input
              placeholder="请输入分类名称"
              v-model="cate_form.classifyName"
              class="w-100"
            ></el-input>
          </el-form-item>
          <el-form-item label="分类图片" prop="picture">
            <UploadPics
              :uploadType="'main'"
              :imgLimit="1"
              :imgSize="'300*300'"
              :imgsPreview="cate_form.picture"
              @getImgData="getImgData"
              v-if="isShowDia"
            />
          </el-form-item>
          <el-form-item label="状态" prop="isShow">
            <el-radio v-model="cate_form.isShow" :label="1">显示</el-radio>
            <el-radio v-model="cate_form.isShow" :label="0">隐藏</el-radio>
          </el-form-item>
          <el-form-item label="排序" prop="classifySort">
            <el-input-number
              v-model="cate_form.classifySort"
              placeholder="请输入排序"
            />
          </el-form-item>
          <el-form-item label="上级分类" prop="parentId">
            <el-select
              placeholder="请选择上级分类"
              v-model="cate_form.parentId"
              class="w-100"
            >
              <el-option
                v-for="(item, index) in cateParentList"
                :key="index"
                :label="item.classifyName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button
            plain
            size="small"
            class="form-button-w70"
            @click="isShowDia = false"
            >取消</el-button
          >
          <el-button
            class="form-button-w70"
            size="small"
            type="primary"
            @click="submit"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import UploadPics from "@/components/UploadPics";
import {
  getProcudtCateList,
  getCateParentList,
  subCateForm,
  deleteCate,
} from "@/api/online_department_store/categories_manage";
export default {
  components: {
    Pagination,
    UploadPics,
  },
  data() {
    return {
      // 顶部搜索栏数据
      classifyName: "",
      // 会员表格数据内容
      tableData: [],
      isShowDia: false,
      opeType: "",
      cate_form: {
        classifyName: "",
        picture: "",
        isShow: null,
        parentId: null,
        classifySort: null,
        goodsType: 3,
      },
      rules: {
        classifyName: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
        classifySort: [
          { required: true, message: "排序不能为空", trigger: "change" },
          { type: "number", message: "只能为数字", trigger: "change" },
        ],
        picture: [{ required: true, message: "图片不能为空" }],
        isShow: [{ required: true, message: "状态不能为空" }],
        parentId: [{ required: true, message: "上级分类不能为空" }],
      },
      cateParentList: [
        {
          value: 0,
          label: "第一级目录",
        },
      ],
      multipleSelection: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  mounted() {
    this.getCate();
  },
  methods: {
    selectable(row, index) {
      if (!row.children) {
        return true;
      } else {
        return false;
      }
    },
    // 获取分类列表
    getCate(ret) {
      if (ret == 1) {
        this.pageNum = 1;
        this.pageSize = 10;
        this.classifyName = "";
      }
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        classifyName: this.classifyName,
        goodsType: 3,
      };
      getProcudtCateList(params).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.body.total;
          this.tableData = res.data.body.list;
          this.tableData.map((item) => {
            item.picture = this.picsPath(item.picture);
          });
        }
      });
    },
    //获取上级分类列表
    getfirstClassify() {
      let params = {
        firstClassify: 1,
      };
      getProcudtCateList(params).then((res) => {
        if (res.data.code == 200) {
          res.data.body.unshift({
            id: 0,
            classifyName: "第一级目录",
          });
          this.cateParentList = res.data.body;
        }
      });
    },
    // 重置搜索关键词
    reset() {
      this.getCate(1);
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    deleteAll() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "info",
          message: "请先选择要批量删除的分类",
        });
      } else {
        // 批量删除
        let multipleData = JSON.parse(JSON.stringify(this.multipleSelection));
        let arr = [];
        let ids = [];
        for (let item of multipleData) {
          // console.log(item);
          if (item.children) {
            arr.push(item.classifyName);
            item.children.forEach((ele) => ids.push(ele.id));
          }
          ids.push(item.id);
        }
        this.$confirm("确认批量删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          this.deleteDate({ ids });
        });
      }
    },
    //获取分类详情
    getCateDetailHandle(id) {
      getCateDetail(id).then((res) => {
        let data = res.data.body;
        this.cate_form = {
          classifyName: data.classifyName,
          picture: data.picture,
          isShow: data.isShow,
          parentId: data.parentId ? data.parentId : "",
          classifySort: data.classifySort ? String(data.classifySort) : "",
        };
      });
    },
    indexMethod(index) {
      // 自定义排序号
      return index + (this.pageNum - 1) * this.pageSize + 1;
    },
    handleSelectionChange(val) {
      //多选改变
      // console.log(val);
      this.multipleSelection = val;
    },
    showDialog(type, row) {
      this.opeType = type;
      this.getfirstClassify();
      if (row != undefined && row.id != "undefined") {
        this.cate_form = { ...row };
      }
      if (type == "add") {
        this.clearDialog();
      }
      this.isShowDia = true;
    },
    clearDialog() {
      this.cate_form = {
        classifyName: "",
        picture: "",
        isShow: null,
        parentId: "",
        classifySort: null,
        goodsType: 3,
      };
    },
    deleteDate(data) {
      // 删除
      deleteCate(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getCate();
        }
      });
    },
    deleteDateOne(id) {
      this.deleteDate({ ids: [id] });
    },
    getImgData(imgData, type) {
      if (type == "main") this.cate_form.picture = imgData.mainImg;
    },
    submit() {
      this.$refs["cate_form"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        this.submitForm();
      });
    },
    submitForm() {
      let data = this.cate_form;
      // console.log(data);
      this.$refs["cate_form"].validate((valid) => {
        if (valid) {
          // console.log(this.opeType);
          subCateForm(data).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.isShowDia = false;
              this.clearDialog();
              this.picList = [];
              this.getCate();
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-edit {
  color: #409eff;
  cursor: pointer;
}
.bg-red {
  background: #f56c6c;
}
</style>

